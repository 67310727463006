const initialState = {
  resLogin: null,
  userProfile: null,
};

const authReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_LOGIN":
      return {
        ...state,
        resLogin: data,
      };
    case "SET_USERPROFILE":
      return {
        ...state,
        userProfile: data,
      };
    default:
      return state;
  }
};

export default authReducer;
