import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Text from "../text";
import ButtonBase from "@mui/material/ButtonBase";
import { useNavigate } from "react-router-dom";

function CardDoctor(props) {
  const { linkFoto, namaDoctor, spesialis, hospital } = props;
  let history = useNavigate();
  return (
    <Card sx={{ maxWidth: 197 }}>
      <ButtonBase
        onClick={() => history("/detailDoctor")}
        sx={{ display: "block", textAlign: "initial" }}
      >
        <CardMedia component="img" height="186" image={linkFoto} alt="doctor" />
        <CardContent>
          <Text
            text={namaDoctor}
            sx={{ fontSize: 14, fontWeight: "600", color: "#2A2A2A" }}
          />
          <Text
            text={spesialis}
            sx={{ fontSize: 14, fontWeight: "400", color: "#2A2A2A" }}
          />
          <Text
            text={hospital}
            sx={{ fontSize: 12, fontWeight: "400", color: "#959595" }}
          />
        </CardContent>
      </ButtonBase>
    </Card>
  );
}

export default CardDoctor;
