import React, { PureComponent } from "react";
import DataTable from "react-data-table-component";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Box,
  Paper,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { Text } from "../../component";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function LaporanSpv() {
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const customStyles = {
    rows: {
      style: {
        height: 36, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 36,
        backgroundColor: "#F8F8F8",
        fontFamily: "Poppins",
        justifyContent: "left",
      },
    },
    headCells: {
      style: {
        minHeight: 36,
        fontWeight: "bold",
        fontSize: 12,
      },
    },
    cells: {
      style: {
        minHeight: 36,
      },
    },
  };
  const columns = [
    {
      name: "Sales Supervisor",
      selector: (row) => row.salesSpv,
    },
    {
      name: "Nama Cabang",
      selector: (row) => row.cabang,
    },
    {
      name: "Total Penyaluran",
      selector: (row) => row.penyaluran,
    },
    {
      name: "Target",
      selector: (row) => row.target,
    },
    {
      name: "Aksi",
      selector: (row) => (
        <Button onClick={handleClickOpen} variant="contained">
          Detail
        </Button>
      ),
    },
  ];

  const data = [
    {
      id: 1,
      salesSpv: "Achmad Fatria R",
      cabang: "Cabang Utama Banjarmasin",
      penyaluran: "Rp1.390.918.576",
      target: "Rp600.000.000",
    },
    {
      id: 2,
      salesSpv: "Supian",
      cabang: "Cabang Banjar Baru",
      penyaluran: "Rp2.099.452.260",
      target: "Rp600.000.000",
    },
    {
      id: 3,
      salesSpv: "Arif Wibowo",
      cabang: "Cabang Marabahan",
      penyaluran: "Rp3.443.009.364",
      target: "Rp600.000.000",
    },
    {
      id: 4,
      salesSpv: "Fatra Kadafi",
      cabang: "Cabang Pelaihari",
      penyaluran: "Rp726.444.448",
      target: "Rp600.000.000",
    },
    {
      id: 5,
      salesSpv: "Rizki Adha Waas",
      cabang: "Cabang Martapura",
      penyaluran: "Rp2.457.941.210",
      target: "Rp600.000.000",
    },
    {
      id: 6,
      salesSpv: "Yongki Arifin",
      cabang: "Cabang Batu Licin",
      penyaluran: "Rp1.158.905.634",
      target: "Rp600.000.000",
    },
    {
      id: 7,
      salesSpv: "Sunaryo",
      cabang: "Cabang Kota Baru",
      penyaluran: "Rp1.357.148.455",
      target: "Rp600.000.000",
    },
    {
      id: 8,
      salesSpv: "Muhammad Samsul Khair",
      cabang: "Cabang Rantau",
      penyaluran: "Rp1.532.591.290",
      target: "Rp600.000.000",
    },
    {
      id: 9,
      salesSpv: "Khaidir Sani",
      cabang: "Cabang Kandangan",
      penyaluran: "Rp1.866.965.284",
      target: "Rp600.000.000",
    },
    {
      id: 10,
      salesSpv: "Amino Rahman",
      cabang: "Cabang Barabai",
      penyaluran: "Rp1.187.018.911",
      target: "Rp600.000.000",
    },
  ];

  const karyawanTerbaik = [
    {
      name: "Achmad",
      SPV: 50,
    },
    {
      name: "Supian",
      SPV: 43,
    },
    {
      name: "Arif",
      SPV: 32,
    },
    {
      name: "Fatra",
      SPV: 27,
    },
    {
      name: "Rizki",
      SPV: 18,
    },
  ];

  const karyawanTerburuk = [
    {
      name: "Yongki",
      SPV: 0,
    },
    {
      name: "Sunaryo",
      SPV: 0,
    },
    {
      name: "Samsul",
      SPV: 3,
    },
    {
      name: "Khaidir",
      SPV: 5,
    },
    {
      name: "Amino",
      SPV: 8,
    },
  ];
  return (
    <div>
      <Stack direction={"row"} sx={{ marginBottom: 10 }}>
        <Box width={"50%"} height={250}>
          <Text
            text={"Best Performance"}
            sx={{
              fontSize: 16,
              color: "#000000",
              fontWeight: "600",
              marginBottom: 1,
            }}
          />
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={karyawanTerbaik}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis
                label={{ value: "Milyar", angle: -90, position: "insideLeft" }}
              />
              <Tooltip />
              <Legend />

              <Bar dataKey="SPV" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
        <Box width={"50%"} height={250}>
          <Text
            text={"Under Performance"}
            sx={{
              fontSize: 16,
              color: "#000000",
              fontWeight: "600",
              marginBottom: 1,
            }}
          />
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={karyawanTerburuk}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis
                label={{ value: "Milyar", angle: -90, position: "insideLeft" }}
              />
              <Tooltip />
              <Legend />

              <Bar dataKey="SPV" fill="red" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Stack>

      <DataTable
        columns={columns}
        data={data}
        pagination
        customStyles={customStyles}
      />

      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Text
            text={"Detail Setiap Cabang"}
            sx={{
              fontWeight: "600",
              fontSize: 18,
              color: "#004591",
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container direction={"row"}>
            <Grid xs={12}>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={6}>
                    <Text
                      text={"Cabang Pembantu Kayu Tangi"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={5}>
                    <Text
                      text={"Rp804.268.642"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={6}>
                    <Text
                      text={"Cabang Pembantu RSUD Ulin "}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={5}>
                    <Text
                      text={"Rp538.002.700"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={6}>
                    <Text
                      text={"Cabang Pembantu Gerai Duta Mall"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={5}>
                    <Text
                      text={"Rp637.313.482"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={6}>
                    <Text
                      text={"Cabang Pembantu Dispenda Prov Kalsel"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={5}>
                    <Text
                      text={"Rp1.407.441.331"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={6}>
                    <Text
                      text={"Cabang Pembantu Pemprov Kal-Sel Banjar Baru"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={5}>
                    <Text
                      text={"Rp124.940.687"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={6}>
                    <Text
                      text={"Cabang Pembantu Banjarmasin Timur / Veteran"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={5}>
                    <Text
                      text={"Rp175.143.407"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={6}>
                    <Text
                      text={"Cabang Pembantu Sentra Antasari"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={5}>
                    <Text
                      text={"Rp597.584.394"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={6}>
                    <Text
                      text={"Cabang Pembantu Sultan Adam"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={5}>
                    <Text
                      text={"Rp280,000,000"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={6}>
                    <Text
                      text={"Cabang Pembantu Teluk Dalam"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={5}>
                    <Text
                      text={"Rp107.000.000"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={6}>
                    <Text
                      text={"Plafon Net"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={5}>
                    <Text
                      text={"Rp27,029,556"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={6}>
                    <Text
                      text={"Cabang Pembantu Gambut"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={5}>
                    <Text
                      text={"Rp309.000.000"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default LaporanSpv;
