import React, { PureComponent } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Stack,
  Divider,
  Box,
  Icon,
  Paper,
} from "@mui/material";
import { ButtonPrimary, Text, TextInput } from "../../component";
import { Images } from "../../assets";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Grid from "@mui/material/Unstable_Grid2";
import { RangeDatePicker } from "react-google-flight-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  getAllCabang,
  getAllMarketing,
  getAllSupervisor,
  getPenyaluranTiapCabang,
  getPenyaluranTiapCabang2,
  getPenyaluranTotalPenyaluran,
  getTotalCabang,
  getTotalMarketing,
  getTotalSPV,
  getTotalTarget,
} from "../../stores";

function Dashboard() {
  const dispatch = useDispatch();
  const karyawan = useSelector((state) => state.karyawanReducer);
  const [open, setOpen] = React.useState(true);
  const [dateRange, setDateRange] = React.useState({});
  const [startDate, setStartDate] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [endDate, setEndDate] = React.useState(new Date());
  const [persenPenyaluaran, setPersenPenyaluran] = React.useState(0);
  const [penyaluran, setPenyaluran] = React.useState(0);
  const [listPenyaluran, setListPenyaluran] = React.useState([]);
  const [totalPenyaluran, setTotalPenyaluran] = React.useState(0);
  const [totalTarget, setTotalTarget] = React.useState(0);
  const [totalSpv, setTotalSpv] = React.useState(0);
  const [totalMarketing, setTotalMarketing] = React.useState(0);
  const [totalCabang, setTotalCabang] = React.useState(0);
  const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "red", "pink"];

  function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  let target = 70200000000;

  React.useEffect(() => {
    getTotalSPV(dispatch).then((response) => {
      setTotalSpv(response.data.total);
    });

    getTotalMarketing(dispatch).then((response) => {
      setTotalMarketing(response.data.total);
    });
    getTotalCabang(dispatch).then((response) => {
      setTotalCabang(response.data.total);
    });
  }, []);

  React.useEffect(() => {
    // getPenyaluranTiapCabang2(dispatch, {
    //   from: startDate.getTime(),
    //   to: endDate.getTime(),
    // });
    getPenyaluranTotalPenyaluran(dispatch, {
      from: startDate.getTime(),
      to: endDate.getTime(),
    }).then((response) => {
      setTotalPenyaluran(response.data.total);
    });

    getTotalTarget(dispatch, {
      from: startDate.getTime(),
      to: endDate.getTime(),
    }).then((response) => {
      setTotalTarget(response.data.total);
    });
    getPenyaluranTiapCabang(dispatch, {
      from: startDate.getTime(),
      to: endDate.getTime(),
    });
  }, [startDate, endDate]);

  React.useEffect(() => {
    let tmp = [];
    karyawan.responPenyaluranCabang &&
      karyawan.responPenyaluranCabang.map((val) => {
        return tmp.push({
          ...val,
          // "Total Penyaluran": !val.cabang ? 0 : val.cabang.totalPenyaluran,
          "Total Penyaluran":
            val.penyaluran.length === 0 ? 0 : val.penyaluran[0].totalPenyaluran,
        });
      });

    console.log(tmp, ">>>>>>>?????????");
    setListPenyaluran(tmp);
  }, [karyawan.responPenyaluranCabang]);

  // React.useEffect(() => {
  //   let total = 0;
  //   karyawan.responPenyaluranCabang.map((val) => {
  //     return (total += !val.cabang ? 0 : val.cabang.totalPenyaluran);
  //   });
  //   let persen = (total / target) * 100;
  //   setPenyaluran(Math.round(total));
  //   setPersenPenyaluran(Math.round(persen));
  // }, [startDate, endDate]);

  const dataPerusahaan = [
    {
      title: "SPV",
      subTitle: `${totalSpv} Karyawan`,
    },
    {
      title: "Marketing",
      subTitle: `${totalMarketing} Karyawan`,
    },
    {
      title: "Cabang",
      subTitle: `${totalCabang} Cabang`,
    },
  ];

  const dataKunjungan = [
    {
      title: "Total Calon Nasabah",
      subTitle: `120`,
    },
    {
      title: "Total Rencana Kunjungan",
      subTitle: `80`,
    },
    {
      title: "Kunjungan Terlaksana",
      subTitle: `60`,
    },
    {
      title: "Persentase Capaian",
      subTitle: `${Math.round(60 / 80) * 100} %`,
    },
  ];

  return (
    <>
      <Stack direction={"row"} spacing={10}>
        <Box width={"40%"}>
          <Text
            text={"Filter Tanggal"}
            sx={{ fontSize: 18, color: "#000000", fontWeight: "600" }}
          />
          <RangeDatePicker
            startDate={startDate}
            endDate={endDate}
            onChange={(startDate, endDate) => {
              setStartDate(new Date(startDate));
              setEndDate(new Date(endDate));
            }}
            // minDate={new Date(1900, 0, 1)}
            // maxDate={new Date(2100, 0, 1)}
            dateFormat="DD/MM/YYYY"
            // monthFormat="MMM YYYY"
            startDatePlaceholder="Start Date"
            endDatePlaceholder="End Date"
            disabled={false}
            className="my-own-class-name"
            startWeekDay="monday"
          />
          <Stack direction={"row"} spacing={2} marginTop={1}>
            <Card elevation={5} sx={{ width: "100%" }}>
              <CardContent sx={{ backgroundColor: "#33CCEB" }}>
                <Text
                  text={"Penyaluran"}
                  sx={{
                    fontSize: 16,
                    color: "#000000",
                    fontWeight: "600",
                  }}
                />
              </CardContent>
              <CardContent>
                <Text
                  text={
                    <NumericFormat
                      value={totalPenyaluran}
                      thousandSeparator={true}
                      prefix={"Rp "}
                      displayType={"text"}
                    />
                  }
                />
              </CardContent>
            </Card>
            <Card elevation={5} sx={{ width: "100%" }}>
              <CardContent sx={{ backgroundColor: "#33CCEB" }}>
                <Text
                  text={"Target"}
                  sx={{
                    fontSize: 16,
                    color: "#000000",
                    fontWeight: "600",
                  }}
                />
              </CardContent>
              <CardContent>
                <Text
                  text={
                    <NumericFormat
                      value={totalTarget}
                      thousandSeparator={true}
                      prefix={"Rp "}
                      displayType={"text"}
                    />
                  }
                />
              </CardContent>
            </Card>
          </Stack>
        </Box>
        <Box>
          <Card elevation={5}>
            <CardContent>
              <Stack direction={"row"} alignItems={"center"}>
                <Box>
                  <Images.BpdLogo style={{ marginLeft: 15, width: 230 }} />
                  {/* <Text
                    text={"BPD Kalimantan Selatan"}
                    sx={{ fontSize: 30, color: "#000000", fontWeight: "600" }}
                  /> */}
                </Box>
              </Stack>

              <Divider sx={{ marginY: "20px" }} />

              <Stack direction={"row"}>
                {/* <Box
                  justifyContent={"center"}
                  alignItems={"center"}
                  display={"flex"}
                  width={"50%"}
                >
                  <Images.LogoBank style={{ width: 100, height: 100 }} />
                </Box> */}
                <Box
                  justifyContent={"center"}
                  alignItems={"center"}
                  display={"flex"}
                  width={"100%"}
                >
                  <Stack direction={"row"} width={"100%"}>
                    <Box
                      justifyContent={"center"}
                      alignItems={"center"}
                      display={"flex"}
                      width={"50%"}
                    >
                      <Stack sx={{ textAlign: "center" }} spacing={2}>
                        <Text
                          text={"Tercapai"}
                          sx={{
                            fontSize: 18,
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        />
                        <Text
                          text={`${
                            totalTarget != 0
                              ? Math.round(
                                  (totalPenyaluran / totalTarget) * 100
                                )
                              : 0
                          }%`}
                          sx={{
                            fontSize: 22,
                            color: "#129900",
                            fontWeight: "800",
                          }}
                        />
                      </Stack>
                    </Box>
                    <Box
                      justifyContent={"center"}
                      alignItems={"center"}
                      display={"flex"}
                      width={"50%"}
                    >
                      <Stack sx={{ textAlign: "center" }} spacing={2}>
                        <Text
                          text={"Target"}
                          sx={{
                            fontSize: 18,
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        />
                        <Text
                          text={"100%"}
                          sx={{
                            fontSize: 22,
                            color: "#1D0ED1",
                            fontWeight: "800",
                          }}
                        />
                      </Stack>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Box>
      </Stack>

      <Box marginTop={"15px"}>
        <Stack direction={"row"} spacing={4}>
          <Box width={"100%"}>
            <Box>
              <Text
                text={"DATA PERUSAHAAN"}
                sx={{ fontSize: 18, color: "#000000", fontWeight: "600" }}
              />
            </Box>
            <Box marginTop={"20px"}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 2, sm: 4, md: 10 }}
              >
                {dataPerusahaan.map((val, index) => (
                  <Grid xs={2} sm={3} md={2} key={index}>
                    <Card elevation={5}>
                      <CardContent sx={{ backgroundColor: "#33CCEB" }}>
                        <Text
                          text={val.title}
                          sx={{
                            fontSize: 16,
                            color: "#000000",
                            fontWeight: "600",
                          }}
                        />
                      </CardContent>
                      <CardContent>
                        <Text text={val.subTitle} />
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Stack>
      </Box>

      <Box marginTop={"15px"}>
        <Stack direction={"row"} spacing={4}>
          <Box width={"100%"}>
            <Box>
              <Text
                text={"DATA KUNJUNGAN NASABAH"}
                sx={{ fontSize: 18, color: "#000000", fontWeight: "600" }}
              />
            </Box>
            <Box marginTop={"20px"}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 2, sm: 4, md: 10 }}
              >
                {dataKunjungan.map((val, index) => (
                  <Grid xs={2} sm={3} md={2} key={index}>
                    <Card elevation={5}>
                      <CardContent
                        sx={{
                          backgroundColor: "#33CCEB",
                          height: "80px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Text
                          text={val.title}
                          sx={{
                            fontSize: 16,
                            color: "#000000",
                            fontWeight: "600",
                          }}
                        />
                      </CardContent>
                      <CardContent>
                        <Text text={val.subTitle} />
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Stack>
      </Box>
      {/* <Box width={"100%"} height={400} marginTop={"30px"}>
        <Text
          text={"Grafik Penyaluran per Bulan"}
          sx={{ marginBottom: "20px" }}
        />
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={dataPerBulan}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="Total"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box> */}
      <Box width={"100%"} height={400} marginTop={"30px"}>
        <Text
          text={"Grafik Penyaluran Cabang"}
          sx={{ marginBottom: "20px", fontWeight: "bold" }}
        />
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={listPenyaluran}
            margin={{
              top: 5,
              right: 30,
              left: 100,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="namaCabang"
              padding={{ left: 30, right: 30 }}
              tick={false}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Total Penyaluran" fill="#8884d8">
              {listPenyaluran.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={getRandomColor()} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
}

export default Dashboard;
