import React from "react";
import { TextField } from "@mui/material";

function TextInput(props) {
  const {
    autoComplete,
    autoFocus,
    classes,
    color,
    defaultValue,
    disabled,
    error,
    FormHelperTextProps,
    fullWidth,
    helperText,
    id,
    InputLabelProps,
    inputProps,
    InputProps,
    inputRef,
    label,
    margin,
    maxRows,
    minRows,
    multiline,
    name,
    onChange,
    placeholder,
    required,
    rows,
    select,
    SelectProps,
    size,
    sx,
    type,
    value,
    variant,
  } = props;
  return (
    <TextField
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      classes={classes}
      color={color}
      defaultValue={defaultValue}
      disabled={disabled}
      error={error}
      FormHelperTextProps={FormHelperTextProps}
      fullWidth={fullWidth}
      helperText={helperText}
      id={id}
      InputLabelProps={InputLabelProps}
      inputProps={inputProps}
      InputProps={InputProps}
      inputRef={inputRef}
      label={label}
      margin={margin}
      maxRows={maxRows}
      minRows={minRows}
      multiline={multiline}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      rows={rows}
      select={select}
      SelectProps={SelectProps}
      size={size}
      sx={sx}
      type={type}
      value={value}
      variant={variant}
    />
  );
}

export default TextInput;
