//Images
import BpdLogo from "./images/logoBpd";
import LoginBg from "./images/bgLogin";
import LogoBap from "./images/bapLogo";
import LogoBapDark from "./images/bapLogoDark";
import LogoBank from "./images/bank";

//Icons
import BellNotifIcon from "./icons/bellNotif";
import SolidChevronDownIcon from "./icons/solidChevronDown";

const Images = { BpdLogo, LoginBg, LogoBap, LogoBapDark, LogoBank };
const Icon = {
  BellNotifIcon,
  SolidChevronDownIcon,
};

export { Images, Icon };
