import React from "react";
import Typography from "@mui/material/Typography";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/700.css";

function Text(props) {
  const {
    text,
    variant,
    sx,
    align,
    children,
    classes,
    component,
    gutterBottom,
    noWrap,
    paragraph,
    onClick,
    variantMapping,
  } = props;
  return (
    <Typography
      onClick={onClick}
      variant={variant}
      sx={[{ fontFamily: "Poppins" }, sx]}
      align={align}
      children={children}
      classes={classes}
      component={component}
      gutterBottom={gutterBottom}
      noWrap={noWrap}
      paragraph={paragraph}
      variantMapping={variantMapping}
    >
      {text}
    </Typography>
  );
}

export default Text;
