import React from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Box,
  Paper,
  Stack,
  ButtonBase,
  Autocomplete,
  TextField,
  Snackbar,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { Text } from "../../component";
import moment from "moment";
import {
  assignMarketingToCabang,
  assignPenugasanMarketing,
  getAllMarketing,
  getPenugasanMarketing,
  getPenyaluranNasabah1,
  uploadPenyaluranNasabah,
} from "../../stores";
import { NumericFormat } from "react-number-format";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";

function AssignMarketing() {
  let history = useNavigate();
  const dispatch = useDispatch();
  const karyawan = useSelector((state) => state.karyawanReducer);
  const [open, setOpen] = React.useState(false);
  const [openDetailNasabah, setOpenDetailNasabah] = React.useState(false);
  const [uploadPenyaluran, setUploadPenyaluran] = React.useState(null);
  const [namaFile, setNamaFile] = React.useState("");
  const [listNasabah, setListNasabah] = React.useState([]);
  const [detailNasabah, setDetailNasabah] = React.useState([]);
  const [colom1, setColom1] = React.useState(12);
  const [colom2, setColom2] = React.useState(0);
  const [listMarketing, setListMarketing] = React.useState([]);
  const [startDate, setStartDate] = React.useState(new Date());
  const [idMarketing, setIdMarketing] = React.useState("");
  const [idNasabah, setIdNasabah] = React.useState([]);
  const [dataNasabah, setDataNasabah] = React.useState([]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const [messageAlert, setMessageAlert] = React.useState(null);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [typeAlert, setTypeAlert] = React.useState("success");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
    setMessageAlert(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenDetailNasabah = () => {
    setOpenDetailNasabah(true);
  };

  const handleCloseDetailNasabah = () => {
    setOpenDetailNasabah(false);
  };

  const customStyles = {
    rows: {
      style: {
        height: 36, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 36,
        backgroundColor: "#F8F8F8",
        fontFamily: "Poppins",
        justifyContent: "left",
      },
    },
    headCells: {
      style: {
        minHeight: 36,
        fontWeight: "bold",
        fontSize: 12,
      },
    },
    cells: {
      style: {
        minHeight: 36,
      },
    },
  };
  const columns = [
    {
      name: "Aksi",
      minWidth: "20px",
      selector: (row, index) => (
        <Stack
          direction={"row"}
          spacing={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {row.status === false ? (
            <ButtonBase
              onClick={() => {
                console.log(row);
                setIdNasabah([...idNasabah, row._id]);
                setDataNasabah([...dataNasabah, row.nama]);
                // setListNasabah([
                //   ...listNasabah.slice(0, index),
                //   { ...row, status: true },
                //   ...listNasabah.slice(index + 1, index.length),
                // ]);
              }}
            >
              <AddIcon />
            </ButtonBase>
          ) : (
            <CheckIcon />
          )}

          {/* <Box>{row.induk}</Box> */}
        </Stack>
      ),
    },

    // {
    //   name: "Cabang",
    //   minWidth: "200px",
    //   selector: (row) => row.cabang,
    // },
    {
      name: "Nama Nasabah",
      minWidth: "200px",
      selector: (row) => row.nama,
    },
    {
      name: "Jatuh Tempo",
      minWidth: "200px",
      selector: (row) => moment(row.jatuhTempo).format("DD MMMM YYYY"),
    },
    {
      name: "Status",
      minWidth: "200px",
      selector: (row) =>
        row.adaTermin != 0
          ? "Ada Jadwal Kunjungan"
          : "Belum Ada Jadwal Kunjungan",
    },
    // {
    //   name: "Outstanding",
    //   minWidth: "200px",
    //   selector: (row) => row.outstanding,
    // },
    // {
    //   name: "Plafon",
    //   minWidth: "200px",
    //   selector: (row) => row.plafon,
    // },
  ];

  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <Box onClick={onClick}>
      <TextField
        value={value}
        sx={{ marginTop: 2, width: 200 }}
        variant="outlined"
        // type={"date"}
        size="small"
      />
    </Box>
  ));

  React.useEffect(() => {
    getPenyaluranNasabah1(dispatch);
    getAllMarketing(dispatch);
  }, []);

  React.useEffect(() => {
    let tmp = [];
    karyawan.responGetMarketing.map((val, index) => {
      tmp.push({
        id: val.idKaryawan,
        label: val.nama,
      });
    });
    setListMarketing(tmp);
  }, [karyawan.responGetMarketing]);

  React.useEffect(() => {
    let tmp = [];
    karyawan.responPenyaluranNasabah1.map((val) => {
      return tmp.push({
        ...val,
        status: false,
        plafon: (
          <NumericFormat
            value={val.plafon}
            thousandSeparator={true}
            prefix={"Rp"}
            displayType={"text"}
          />
        ),
        outstanding: (
          <NumericFormat
            value={val.outstanding}
            thousandSeparator={true}
            prefix={"Rp"}
            displayType={"text"}
          />
        ),
      });
    });
    setListNasabah(tmp);
  }, [karyawan.responPenyaluranNasabah1]);
  return (
    <div>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={typeAlert}
          sx={{ width: "100%" }}
        >
          {messageAlert}
        </Alert>
      </Snackbar>
      <Stack flexDirection={"row"}>
        <Box sx={{ width: "100%" }} justifyContent={"flex-end"} display="flex">
          <ButtonBase
            onClick={() => {
              history("/laporanNasabah");
            }}
          >
            <CloseIcon />
          </ButtonBase>
        </Box>
      </Stack>

      <Box sx={{ marginLeft: 5, height: "100%" }}>
        <Text text={"Assign Marketing"} sx={{ fontWeight: "bold" }} />
        <Grid container spacing={3}>
          <Grid xs={4}>
            <Box marginTop={5}>
              <Autocomplete
                size="small"
                onChange={(event, newValue) => {
                  setIdMarketing(newValue.id);
                }}
                disablePortal
                id="combo-box-demo"
                options={listMarketing}
                sx={{ width: 200 }}
                renderInput={(params) => (
                  <TextField {...params} label="Marketing" />
                )}
              />
            </Box>
            <Box>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                customInput={<ExampleCustomInput />}
              />
            </Box>
            <Box marginTop={2}>
              <Button
                disabled={idMarketing != "" ? false : true}
                onClick={() => {
                  setIdNasabah([]);
                  getPenugasanMarketing(dispatch, {
                    idMarketing: idMarketing,
                    tanggal: startDate.getTime(),
                  }).then((val) => {
                    console.log(val);
                    let tmp = [];
                    let tmpNama = [];
                    val.map((value) => {
                      tmp.push(value.idNasabahTempo);
                      tmpNama.push(value.nama);
                    });
                    setIdNasabah(tmp);
                    setDataNasabah(tmpNama);
                  });
                }}
                variant="contained"
                sx={{ textTransform: "none" }}
              >
                Cari
              </Button>
            </Box>
            <Box
              sx={{
                marginTop: 5,
                borderRadius: 3,
                width: "100%",
                height: "50%",
                border: 2,
                borderColor: "#E0E0E0",
                paddingX: "10px",
                paddingY: "20px",
                overflowY: "scroll",
              }}
            >
              {idNasabah.length === 0 ? (
                <Box>
                  <Text
                    text={"Tidak ada nasabah yang dikunjungi"}
                    sx={{ color: "#dedede" }}
                  />
                </Box>
              ) : (
                dataNasabah.map((val, index) => {
                  return (
                    <Stack direction={"row"} spacing={1} sx={{ marginTop: 1 }}>
                      <Box>
                        <Text text={`${index + 1}.`} />
                      </Box>
                      <Box>
                        <Text text={val} />
                      </Box>
                      <ButtonBase
                        onClick={() => {
                          setDataNasabah([
                            ...dataNasabah.slice(0, index),
                            ...dataNasabah.slice(index + 1, index.length),
                          ]);

                          setIdNasabah([
                            ...idNasabah.slice(0, index),
                            ...idNasabah.slice(index + 1, index.length),
                          ]);
                        }}
                      >
                        <CloseIcon />
                      </ButtonBase>
                    </Stack>
                  );
                })
              )}
            </Box>
            <Box marginTop={2}>
              <Button
                disabled={idMarketing != "" ? false : true}
                onClick={() => {
                  console.log(idMarketing);
                  assignPenugasanMarketing({
                    idMarketing: idMarketing,
                    idNasabah: idNasabah,
                    tanggal: startDate.getTime(),
                  }).then((val) => {
                    if (val === 200) {
                      setTypeAlert("success");
                      setMessageAlert("Berhasil assign penugasan marketing!");
                      setOpenAlert(true);
                      setIdMarketing("");
                      setStartDate(new Date());
                      setIdNasabah([]);
                      setDataNasabah([]);
                      getPenyaluranNasabah1(dispatch);
                    } else {
                      setTypeAlert("error");
                      setMessageAlert("Gagal assign penugasan marketing!");
                      setOpenAlert(true);
                    }
                  });
                }}
                variant="contained"
                sx={{ textTransform: "none" }}
              >
                Save
              </Button>
            </Box>
          </Grid>
          <Grid xs={8}>
            <Box
              sx={{
                borderRadius: 3,
                border: 2,
                borderColor: "#E0E0E0",
                paddingBottom: 1,
              }}
            >
              <DataTable
                columns={columns}
                data={listNasabah}
                pagination
                customStyles={customStyles}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default AssignMarketing;
