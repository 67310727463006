import React from "react";
import Button from "@mui/material/Button";

function ButtonPrimary(props) {
  const {
    variant,
    disabled,
    onClick,
    text,
    href,
    color,
    sx,
    size,
    startIcon,
    endIcon,
    classes,
  } = props;
  return (
    <Button
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      href={href}
      color={color}
      sx={sx}
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
      classes={classes}
    >
      {text}
    </Button>
  );
}

export default ButtonPrimary;
