const initialState = {
  responGetSupervisor: [],
  responGetMarketing: [],
  responGetDirectSales: [],
  responGetCabang: [],
  responPenyaluran: [],
  responPenyaluranCabang: [],
  responPenyaluranNasabah1: [],
  responPenugasanMarketing: [],
  responListNasabah: [],
  responPenyaluranMarketing: [],
};

const karyawanReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_CABANG":
      return {
        ...state,
        responGetCabang: data,
      };
    case "SET_ALLSUPERVISOR":
      return {
        ...state,
        responGetSupervisor: data,
      };
    case "SET_ALLMARKETING":
      return {
        ...state,
        responGetMarketing: data,
      };
    case "SET_ALLDIRECTSALES":
      return {
        ...state,
        responGetDirectSales: data,
      };
    case "SET_PENYALURAN":
      return {
        ...state,
        responPenyaluran: data,
      };
    case "SET_PENYALURANCABANG":
      return {
        ...state,
        responPenyaluranCabang: data,
      };
    case "SET_PENYALURANNASABAH1":
      return {
        ...state,
        responPenyaluranNasabah1: data,
      };
    case "SET_RESPONPENUGASANMARKETING":
      return {
        ...state,
        responPenugasanMarketing: data,
      };
    case "SET_RESPONNASABAH":
      return {
        ...state,
        responListNasabah: data,
      };
    case "SET_PENYALURAN_MARKETING":
      return {
        ...state,
        responPenyaluranMarketing: data,
      };
    default:
      return state;
  }
};

export default karyawanReducer;
